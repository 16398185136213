@import url("./imports.css");

.menu-container {
  height: 100vh;
}

.tulip-container {
  margin-top: 6vh;
  height: 9vh;
}

.tulip {
  width: 20%;
}

.menu {
  width: 100%;
  height: 76vh;
}

.menu_row {
  width: 80%;
  margin-top: 3%;
}

.menu_icon {
  width: 30%;
  margin-right: 2%;
}

.menu_word_en {
  font-family: "Dancing Script", cursive;
  font-size: x-large;
  color: #626262;
  font-weight: 200;
}

.menu_word_gr {
  font-family: "STIX Two Text", serif;
  font-style: italic;
  font-size: large;
  color: #626262;
  font-weight: 100;
}

.language_line {
  height: 7vh;
}

.lucidity {
  height: 3vh;
  font-style: italic;
}

.lucidity_link {
  color: rgb(71, 97, 82);
  padding-left: 1px;
}

.language_link {
  margin: 0 10%;
  color: black;
  text-decoration: none;
}

.language_link:visited {
  text-decoration: none;
}

.selected_language_link {
  text-decoration: underline;
}

@media only screen and (min-width: 800px) {
  .menu {
    margin-top: 100px;
  }
  .menu_row {
    width: 300px;
    margin-top: 30px;
  }
  .tulip {
    margin-top: 100px;
    width: 100px;
  }
  .menu_icon {
    width: 100px;
  }
}
