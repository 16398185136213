.invitation_image {
    width: 90%;
}

.ibans {
    margin-top: 2vh;
    font-family: "EB Garamond", serif;
    width: 100%;
}

.iban {
    font-style: italic;
    font-size: small;
    padding-top: 3px;
}
