@import url("./imports.css");

.tulip_back {
  width: 14%;
  padding-top: 2vh;
  margin-left: 4%;
}

.page_icon {
  width: 33%;
}

.page_info {
  font-family: "EB Garamond", serif;
  width: 77%;
  font-size: medium;
  text-align: center;
  margin-top: 5%;
  line-height: normal;
}

.hotel_list {
  width: 80%;
  margin-top: 3%;
}

.hotel_name {
  text-align: start;
  width: 60%;
}

.hotel_area {
  text-align: end;
  width: 40%;
}

.hotel_link {
  color: black;
  text-decoration: underline;
}

.accommodation_image {
  width: 100%;
  opacity: 70%;
  margin-top: 3%;
}

@media only screen and (min-width: 800px) {
  .page_icon {
    width: 180px;
  }
}
