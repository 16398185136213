@import url("./imports.css");

.tulip_back {
  width: 14%;
  padding-top: 2vh;
  margin-left: 4%;
}

.page_icon {
  width: 33%;
}

.page_info {
  font-family: "Ubuntu", sans-serif;
  width: 77%;
  font-size: medium;
  text-align: center;
  margin-top: 10%;
  line-height: normal;
}

.transpotation_image {
  width: 100%;
  opacity: 25%;
  bottom: 10%;
  position: absolute;
}

@media only screen and (min-width: 800px) {
  .page_icon {
    width: 180px;
  }
  .page_info {
    width: 300px;
  }
  .transpotation_image {
    width: 500px;
  }
}
