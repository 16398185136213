@import url("./imports.css");

.test {
  height: 0;
  transition: 5s;
  visibility: hidden;
}

.tulip_back {
  width: 12%;
  margin: 3%;
}

.page_icon {
  width: 33%;
}

.form_container {
  font-family: "EB Garamond", serif;
  width: 100%;
}

.form {
  margin-top: 10%;
  width: 70%;
}

.submit_button {
  font-family: "EB Garamond", serif;
  border-width: 0;
  background-color: #e0dbbe;
  font-size: large;
  padding: 4px 18px;
  border-radius: 4px;
}

.text_input {
  font-family: "EB Garamond", serif;
  border-width: 0;
  background-color: #f6f0d1;
  border-radius: 5px;
  line-height: 20px;
  padding: 5px;
  font-size: 17px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 1em;
}

.text_input:focus {
  outline: none;
  border: 1px solid #95917f;
  box-shadow: 0 0 2px #95917f;
}

.text_area {
  resize: none;
  height: 8em;
}

.additional_info_label {
  margin-top: 1em;
}

.goodbye_container {
  width: 80%;
  margin-top: 35px;
  padding-bottom: 50px;
}

.goodbye {
  text-align: center;
  width: 70%;
  font-style: italic;
}

.visible_on_positive_attendance {
  opacity: 0.5;
}

/* Styling radio and checkbox inputs, see
https://moderncss.dev/pure-css-custom-styled-radio-buttons/
and https://moderncss.dev/pure-css-custom-checkbox-style/ */

:root {
  --form-control-color: #aba791;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.form-control {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

input[type="radio"] {
  appearance: none;
  background-color: #f6f0d1;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"] {
  appearance: none;
  background-color: #f6f0d1;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

@media only screen and (min-width: 800px) {
  .page_icon {
    width: 180px;
  }
  .form {
    width: 400px;
    margin-top: 70px;
  }
}

.disabled_button {
  opacity: 0.6;
}
.disabled_button:hover {
  cursor: not-allowed;
}

.error_p {
  color: #ce5656;
  line-height: 30px;
  text-align: center;
}
