@import url("./imports.css");

.tulip_back {
  width: 14%;
  padding-top: 2vh;
  margin-left: 4%;
}

.page_icon {
  width: 33%;
}

.page_info {
  font-family: "EB Garamond", serif;
  width: 77%;
  font-size: medium;
  text-align: center;
  margin-top: 15%;
  line-height: normal;
}

.pre_wedding_image {
  width: 100%;
  opacity: 15%;
  bottom: 15%;
  position: absolute;
}

@media only screen and (min-width: 800px) {
  .page_icon {
    width: 180px;
  }
  .page_info {
    width: 300px;
  }
  .pre_wedding_image {
    width: 500px;
  }
  .google_maps_icon {
    width: 26px !important;
  }
}
