@import url("./imports.css");

.tulip_back {
  width: 14%;
  padding-top: 2vh;
  margin-left: 4%;
}

.page_icon {
  width: 30%;
}

.page_info {
  font-family: "EB Garamond", serif;
  width: 80%;
  font-size: medium;
  text-align: center;
  margin-top: 5%;
  line-height: normal;
}

.image_container {
  position: relative;
  height: 17em;
  width: 100%;
  margin-top: 10%;
}

.venue_image {
  position: absolute;
  z-index: 10;
  width: 14em;
  margin-left: calc(50% - 7em);
  margin-top: 4%;
  border-radius: 40px;
}

.image_background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.left_background {
  background-color: #e0dbbe;
  width: 40%;
  height: 100%;
}

.right_background {
  background-color: #f6f0d1;
  width: 60%;
  height: 100%;
}

.google_link_row {
  position: absolute;
  z-index: 10;
  bottom: 2%;
  width: 100%;
  text-decoration: underline;
}

.google_link {
  color: black;
}

.google_maps_icon {
  width: 8%;
}

@media only screen and (min-width: 800px) {
  .page_info {
    width: 500px;
  }
  .page_icon {
    width: 180px;
  }
}
