@import url("./imports.css");

.index-background {
  position: relative;
  width: 100vh;
  height: 100vh;
}

.ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
}

.lr {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
}

.c {
  width: 16em;
  height: 20em;
}

.names {
  width: 10em;
}

.central {
  width: 100%;
  height: 100vh;
}

@media only screen and (min-width: 800px) {
  .pc_background {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .index-background {
    width: 100%;
  }
  .ul {
    width: 10%;
  }
  .lr {
    width: 10%;
  }
}
